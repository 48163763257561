import React, {FC} from 'react';
import {ICaseListProps} from '@common/types';
import TimeLineItem from '@components/component/TimeLineItem';
import {EBlockPosition} from '@redux/types';


const ListTimeline: FC<ICaseListProps> = ({ items, height, position, type }) => {
  const defaultClassNames = [
    'project ', 'project--margin-top-big',
    !height ? '' : `project--height-${height}`,
    position === EBlockPosition.outsideStep ?  'project--tablet-font-middle' : ''
      // type === EBlockType.sticky ? 'inner-simple-table--no-padding inner-simple-table--margin-top-no inner-simple-table--margin-10-10-5' :
      //   type === EBlockType.simple ? 'inner-simple-table--no-padding inner-simple-table--margin-top-big' :
      //     'inner-simple-table--no-padding inner-simple-table--margin-top-middle inner-simple-table--margin-25-25-5',
  ]

  return (
    <div className={defaultClassNames.join(' ')}>
      {items.map((item, index) => (
        <TimeLineItem isDate={items?.some(i => !!i?.date)} item={item} rowIndex={index} key={`timeline_${item?.slug || ''}_${index}`}/>
      ))}
    </div>
  )
}

export default ListTimeline
