import React, {FC, memo} from 'react';
import {ITableProps} from '@common/types';
import {isNotEmptyRichContent} from '@common/functions';
import RichTextRender from '@components/component/RichTextRender';
import Text from '@components/Text';
import SimpleTags from '@components/component/Tags/SimpleTags';

const Table: FC<ITableProps> = ({ items }) => {
  return (
    <ul className="inner-table__list">
      {items?.map((item, index) => {

        const emptyText = !isNotEmptyRichContent(item?.text)

        return (
          <li key={index} className="inner-table__item">
            <span className="inner-table__key"><Text text={item.title}/></span>
            {(!emptyText || item?.description) && (
              <>
                {emptyText ? (<p className="inner-table__value"><Text text={item.description}/></p>) : (
                  <div className={"inner-table__value"}>
                    <RichTextRender content={item?.text || []}/>
                  </div>
                )}
              </>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default memo(Table)
