import React, {FC, memo} from 'react';
import {IGridProps} from '@common/types';
import Link from 'next/link';
import Picture from '@components/component/Picture';
import {EComponent} from '@redux/types';
import RichTextRender from '@components/component/RichTextRender';
import Text from '@components/Text';


const ProductsGrid: FC<IGridProps> = ({items}) => {

  //TODO: отдельно проверить все страницы, где должны быть стили с products
  const isLink = items.some(i => !!i.url)

  return (
    <ul className={'products__list'}>
      {items?.map((c, index) => {
        const cardTypeLink = c.type === 'rounded-right' ? 'products__link--radius-right' : c.type === 'rounded' ? 'products__link--radius-all' : ''
        const Card = () => {
          const image = c?.image && <Picture media={{__component: EComponent.MEDIA, image: c.image}} withoutWrapper={isLink}/>
          return (
            <>
              <div className="products__picture">{image}</div>
              <h3 className={'products__title'}>
                <Text text={c.title}/>
              </h3>
              {c?.text && (
                <div className={'products__description'}>
                  <RichTextRender content={c?.text}/>
                </div>
              )}
            </>
          )
        }
        return (
          <li className={'products__item'} key={`card_${c.id}_${index}`} >
            <Link className={['products__link', cardTypeLink].join(' ')} href={c.url ?? '#main-products'}>
              <Card/>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(ProductsGrid)
