import React, {FC, memo} from 'react';
import {IGridProps} from '@common/types';
import Picture from '@components/component/Picture';
import {EBlockPosition, EComponent} from '@redux/types';
import RichTextRender from '@components/component/RichTextRender';
import Text from '@components/Text';


const ToolsGrid: FC<IGridProps> = ({items, position}) => {

  const twoElementsClasses = ` inner-tools--2-items${position === EBlockPosition.insideStepDescription ? '' : ' two-part'}`

  return (
    <ul className={`inner-tools__list${items?.length <= 2 ? twoElementsClasses : ''}`}>
      {items?.map((c, index) => {
        const cardType = c.type === 'rounded-right' ? 'inner-tools__item--border-right' : c.type === 'rounded' ? 'inner-tools__item--border-round' : ''
        const Card = () => (
          <>
            {c?.image && <Picture media={{__component: EComponent.MEDIA, image: c.image}}/>}
            <h3 className={'inner-tools__heading'}>
              <Text text={c.title}/>
            </h3>
            {c?.text && (
              <div className={'inner-tools__description'}>
                <RichTextRender content={c?.text}/>
              </div>
            )}
          </>
        )
        return (
          <li className={['inner-tools__item', cardType].join(' ')} key={`card_${c.id}_${index}`} >
            <Card/>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(ToolsGrid)
