import React, {FC, memo, useEffect} from 'react';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import {Form, Input} from 'antd';
import {IconLoader} from '@components/CustomIcons';
import Icon from '@components/Icon';
import {FORM_EMAIL_VALIDATE_RULE, FORM_REQUIRED_RULE} from '@common/constants';
import {IMaskInput} from 'react-imask';
import {useRequestForm} from '@common/hooks/useRequest';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {EBlockPosition} from '@redux/types';


const FormBlock: FC<{color?: string, title?: string, position?: EBlockPosition}> = ({ color, title, position }) => {
  const t = useTranslation('forms') as ITranslation['forms']
  const {form, checkFieldLabel, setFocusedInput, sendForm} = useRequestForm('block')
  const {blockForm} = useTypedSelector(state => state.app)

  useEffect(() => {
    if (blockForm?.isClear) {
      setTimeout(() => {
        document.querySelectorAll('.custom-input__label').forEach(element => {
          element.classList.remove('is-focus')
        })
      }, 300)
    }
  }, [blockForm?.isClear])

  return (
    <div className={`form-block is-initialized${position === EBlockPosition.outsideStep ? '' : ' mt-130 mt-sm-100'}`}
         data-validate="" data-callback="similarTask">
      <div className="form-block__wrapper">
        <h2 className="form-block__title">
          {title || t.blockTitle}
        </h2>
        <Form
          form={form}
          onFocus={(e) => setFocusedInput(e.target.id ?? '')}
          onBlur={() => setFocusedInput('')}
          onFinish={sendForm}
        >
          <div className="form-block__inputs-wrapper">
            <Form.Item noStyle={true} name="nameContact" rules={[FORM_REQUIRED_RULE]}>
              <div className="custom-input form-modal__input-box">
                <label>
                  <Input id="nameContact" />
                  <span className={`custom-input__label ${checkFieldLabel('nameContact') ? 'is-focus' : ''}`}>
                    {t.name}
                  </span>
                </label>
              </div>
            </Form.Item>

            <Form.Item noStyle={true} name="nameCompany" rules={[FORM_REQUIRED_RULE]}>
              <div className="custom-input form-modal__input-box">
                <label>
                  <Input id="nameCompany"/>
                  <span className={`custom-input__label ${checkFieldLabel('nameCompany') ? 'is-focus' : ''}`}>
                    {t.company}
                  </span>
                </label>
              </div>
            </Form.Item>

            <Form.Item noStyle={true} name="email" rules={[FORM_REQUIRED_RULE, FORM_EMAIL_VALIDATE_RULE]}>
              <div className="custom-input form-modal__input-box">
                <label>
                  <Input id="email"/>
                  <span className={`custom-input__label ${checkFieldLabel('email') ? 'is-focus' : ''}`}>
                    {t.email}
                  </span>
                </label>
              </div>
            </Form.Item>

            <Form.Item noStyle={true} name="phone" rules={[FORM_REQUIRED_RULE]}>
              <div className="custom-input form-request__input-box">
                <label>
                  <IMaskInput mask="+{7} (000) 000 - 00 - 00" id="phone" />
                  <span className={`custom-input__label ${checkFieldLabel('phone') ? 'is-focus' : ''}`}>
                    {t.phone}
                  </span>
                </label>
              </div>
            </Form.Item>
          </div>

          <button className={`form-block__button btn${!color? '' : ` btn--${color}`} is-${blockForm?.status}`} type="submit">
            <span className="form-block__button-text">{t.button}</span>
            <IconLoader/>
            <Icon
              width={19}
              height={13}
              name="icon-success"
              className="form-block__button-icon form-block__button-success"
            />
          </button>
        </Form>
      </div>
    </div>
  )
}

export default memo(FormBlock)
