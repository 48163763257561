import React, {FC} from 'react';
import {ICaseListItem, IStepChildrenProps} from '@common/types';
import {
  EBlockPosition,
  EBlockType,
  EComponent,
  IAccordionComponent,
  IBannerComponent,
  ICaseListComponent,
  IEmbedComponent,
  IFormBlockComponent,
  IGridComponent,
  IListComponent,
  IMediaComponent,
  IQuoteComponent,
  IStackComponent,
  ITagListComponent,
  IVacancyListComponent,
  TStepChildPosition
} from '@redux/types';
import Grid from '@components/component/Grid';
import List from '@components/component/List';
import Picture from '@components/component/Picture';
import RichTextRender from '@components/component/RichTextRender';
import VacancyList from '@components/component/VacancyList';
import Quote from '@components/component/Quote';
import Banner from '@components/component/Banner';
import Table from '@components/Table';
import Accordion from '@components/component/Accordion';
import EmbedMedia from '@components/component/EmbedMedia';
import SimpleTags from '@components/component/Tags/SimpleTags';
import FormBlock from '@components/component/FormBlock';
import StackGrid from '@components/component/Grids/StackGrid';
import ListTimeline from '@components/component/ListTimeline';


const StepChildren: FC<IStepChildrenProps> = ({components, parentStep}) => {

  return (
    <>
      {components.map(((c, i) => {
        const key = `step_item_${i}_${c.__component}`
        switch (c.__component) {
          case EComponent.GRID:
            const grid = c as IGridComponent
            return <Grid key={key} items={grid.cards} parent={parentStep}
                         height={grid?.height}
                         type={grid?.placement?.type || EBlockType.grid}
                         position={grid?.placement?.position || EBlockPosition.insideStep} />
          case EComponent.STACK_LIST:
            const stacks = c as IStackComponent
            return <StackGrid key={key} items={stacks.stacks}/>
          case EComponent.TAG_LIST:
            const tagList = c as ITagListComponent
            const classNames = tagList?.placement?.type === EBlockType.simple ? 'inner-styled-list--padding-big mt-65 mt-md-65 mt-sm-40' :
              tagList?.placement?.type === EBlockType.grid ? 'inner-styled-list--padding-middle mt-40 mt-md-40 mt-sm-20' : ' mt-20 mt-md-20 mt-sm-10';
            return <SimpleTags key={key} tags={tagList?.tags?.map(t => t.title) || []} classNames={classNames}/>
          case EComponent.CASE_LIST:
            const caseList = c as ICaseListComponent
            const cases = caseList.cases as ICaseListItem[]
            if (parentStep?.type === 'inner-table') {
              return (
                <Table
                  key={key}
                  items={cases?.map((e) => ({
                    title: e?.title || '',
                    description: e?.description,
                    text: e?.text
                  })) || []}
                />
              )
            }
            return <ListTimeline key={key} items={cases}
                                 height={caseList?.height}
                                 type={caseList.placement?.type || EBlockType.grid}
                                 position={caseList.placement?.position as TStepChildPosition || EBlockPosition.insideStep} />

          case EComponent.LIST:
            const list = c as IListComponent
            if (parentStep?.type === 'inner-table') {
              return (
                <Table
                  key={key}
                  items={list.lists?.map((e) => ({
                    title: e?.title || '',
                    description: e?.description,
                    text: e?.text
                  })) || []}
                />
              )
            }
            return <List key={key} items={list.lists}
                           height={list?.height}
                           type={list.placement?.type || null}
                           position={list.placement?.position as TStepChildPosition || EBlockPosition.insideStep}/>
          case EComponent.MEDIA:
            const media = c as IMediaComponent

            const positionClassName = c?.placement?.position === EBlockPosition.outsideStep && c?.placement?.type !== EBlockType.sticky ? 'mt-0 mt-md-0 mt-sm-0' :
              (c?.placement?.type === EBlockType.simple ? ' mt-150 mt-md-65 mt-sm-40' :
              c?.placement?.type === EBlockType.grid ? ' mt-65 mt-md-65 mt-sm-40' :
                c?.placement?.type === EBlockType.sticky ? 'mt-40 mt-md-20 mt-sm-15' : 'mt-65 mt-md-40 mt-sm-40')

            return <Picture key={key} media={media}
                            wrapperClassName={`${positionClassName}${c?.placement?.position !== EBlockPosition.insideStepDescription ? '' : ' mb-sm-20'}`}/>
          case EComponent.EMBED:
            const embed = c as IEmbedComponent
            return <EmbedMedia key={key} embed={embed?.embed}
                               position={embed?.placement?.position || EBlockPosition.insideStep}
                               type={embed?.placement?.type || EBlockType.grid}/>
          case EComponent.VACANCY_LIST:
            const vacancyList = c as IVacancyListComponent
            return (
              <VacancyList
                key={key}
                items={vacancyList.vacancies?.data.map(v => v.attributes).map(v => ({
                  slug: `/vacancies/${v.slug}`,
                  title: v.title,
                  meta: {
                    onlyPreview: v.meta?.onlyPreview ?? false
                  }
                })) || []}
              />
            )
          case EComponent.PARAGRAPH:
            const paragraph = c as any
            return <RichTextRender key={key} title={paragraph?.title}
                                   type={c?.placement?.type || EBlockType.grid}
                                   position={c?.placement?.position || EBlockPosition.insideStep}
                                   parent={parentStep || null}
                                   content={paragraph?.text}/>
          case EComponent.QUOTE:
            const quote = c as IQuoteComponent
            return <Quote quote={quote} key={key}/>
          case EComponent.BLOCK:
            const banner = c as IBannerComponent
            return <Banner banner={banner} key={key}/>
          case EComponent.ACCORDION:
            const accordion = c as IAccordionComponent
            return <Accordion items={accordion?.spoilers || []} key={key}/>
          case EComponent.FORM_BLOCK:
            const formBlock = c as IFormBlockComponent
            return <FormBlock color={formBlock.color || ''} key={key} title={formBlock?.title} position={formBlock?.placement?.position} />
        }
      }))}
    </>
  )
}

export default StepChildren
