import React, {FC, memo, ReactNode, useMemo} from 'react';
import {EPage, IStepProps} from '@common/types';
import StepChildren from '@components/component/StepChildren';
import Link from 'next/link';
import {EBlockPosition, EBlockType, EComponent, IStepComponent, TComponent} from '@redux/types';
import RichTextRender from '@components/component/RichTextRender';
import {isNotEmptyRichContent} from '@common/functions';
import Text from '@components/Text';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {useStepHeaderSettings} from '@common/hooks/useStepHeaderSettings';


const Step: FC<IStepProps> = ({count, step, children, classNames, stepChildren,
                                link, id, childrenPosition}) => {


  const emptyNote = useMemo(() => !isNotEmptyRichContent(step?.note), [step])

  const typeClassName = step?.type === EBlockType.sticky && !step?.displayStepNumber && !step?.title && !step.name ? EBlockType.grid : step?.type || EBlockType.grid;
  const asideClassName = (step?.type !== EBlockType.grid) || emptyNote ? '' : ' inner-grid--aside-left';

  if (step?.hidden) {
    return null
  }

  return (
    <>
      <section
        className={`${typeClassName} ${classNames?.join(' ') ?? ''}${asideClassName}`}
        id={step?.anchor ?? id ?? ''}
      >
        <div className="container">
          {step?.displayStepNumber && (
            <div className="section-heading">
              <span className="section-heading__count">{count < 10 ? '0' : ''}{count}</span>
              <h2 className="section-heading__title"><Text text={step.name}/></h2>
              {link && (
                <>
                  <Link className="section-heading__link section-heading__link--desktop link" href={link.url}>
                    {link.title}
                  </Link>
                  <Link className="section-heading__link section-heading__link--mobile link" href={link.url}>
                    {link.titleMobile ?? link.title}
                  </Link>
                </>
              )}
            </div>
          )}

          {(!step?.type || step?.type === EBlockType.grid || step?.type === EBlockType.sticky) && (
            <StepGridHeader step={step} stepChildren={stepChildren || []}>
              {childrenPosition === EBlockPosition.insideStepDescription ? children : null}
            </StepGridHeader>
          )}

          {(step?.type === EBlockType.simple) && (
            <StepSimpleBlockHeader step={step} stepChildren={stepChildren || []}>
              {childrenPosition === EBlockPosition.insideStepDescription ? children : null}
            </StepSimpleBlockHeader>
          )}

          {step?.type === EBlockType.table && (
            <StepTableHeader step={step} stepChildren={stepChildren || []}>
              {childrenPosition === EBlockPosition.insideStepDescription ? children : null}
            </StepTableHeader>
          )}

          {step?.type === EBlockType.tools && (
            <StepToolsHeader step={step} stepChildren={stepChildren || []}>
              {childrenPosition === EBlockPosition.insideStepDescription ? children : null}
            </StepToolsHeader>
          )}

          <>
            {!!stepChildren?.length ? stepChildren?.map(stepChild => (
              (!stepChild?.placement?.position || stepChild?.placement?.position === EBlockPosition.insideStep) && (
                <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>)
            )) : !childrenPosition || childrenPosition === EBlockPosition.insideStep ? children : null}
          </>
        </div>
      </section>

      {stepChildren?.map(stepChild => ((stepChild?.placement?.position === EBlockPosition.outsideStep) && (
        <section className={stepChild?.placement?.type || EBlockType.grid} key={stepChild?.id || ''}>
          <div className="container">
            <StepChildren components={[stepChild]} parentStep={step}/>
          </div>
        </section>
      ))) || (childrenPosition === EBlockPosition.outsideStep ? children : null)}
    </>
  )
}

const StepGridHeader: FC<{step: IStepComponent, stepChildren: TComponent[], children?: ReactNode}> = (
  {step, stepChildren, children}) => {

  const {emptyText, emptyNote, stepDescriptionInsideStepCount, headerClasses} = useStepHeaderSettings({
    step, stepChildren, children,
    classes: {
      mainClass: 'inner-simple-title',
      emptyMarginClass: 'inner-simple-title--no-margin mt-65 mt-md-40',
      mobileNormalTitleClass: 'inner-simple-title--font-40-40-40 inner-simple-title--mobile-margin-middle'
    }
  })

  if (!(step?.title || !emptyText || stepDescriptionInsideStepCount || children)) {
    return null
  }

  return (
    <>
      {!emptyNote && (
        <div className="inner-grid__aside inner-grid__aside--mobile-top">
          <div className="inner-simple-text inner-simple-text--mobile-margin-big">
            <RichTextRender content={step?.note}/>
          </div>
        </div>
      )}

      <div className="inner-grid__content">
        {step?.title && (
          <h2 className={headerClasses?.join(' ')}>
            <Text text={step.title}/></h2>
        )}
        {!emptyText && (
          <RichTextRender content={step.description} parent={step}/>
        )}
        {!!stepDescriptionInsideStepCount ? stepChildren?.map(stepChild => (
          stepChild?.placement?.position === EBlockPosition.insideStepDescription && <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>
        )) : children}
      </div>
    </>
  )
}

const StepSimpleBlockHeader: FC<{step: IStepComponent, stepChildren: TComponent[], children?: ReactNode}> = (
  {step, stepChildren, children}) => {

  const {emptyText, emptyNote, stepDescriptionInsideStepCount, headerClasses} = useStepHeaderSettings({
    step, stepChildren, children,
    classes: {
      mainClass: 'inner-simple-title',
      emptyMarginClass: 'inner-simple-title--no-margin mt-65 mt-md-40',
      mobileNormalTitleClass: 'inner-simple-title--font-40-40-40 inner-simple-title--mobile-margin-middle'
    }
  })

  if (!(step?.title || !emptyText || stepDescriptionInsideStepCount || children)) {
    return null
  }

  return (
    <div className="inner-simple-block__content">
      {!emptyNote && (
        <div className="aside inner-simple-block__aside--mobile-bottom inner-simple-block__aside inner-simple-block__aside--margin-big">
          <div className="aside__text">
            <RichTextRender content={step?.note}/>
          </div>
        </div>
      )}

      {(step?.title || !emptyText || stepDescriptionInsideStepCount || children) && (
        <div className="inner-simple-block__description">
          {step?.title && (
            <h2 className={headerClasses?.join(' ')}><Text text={step.title}/></h2>
          )}
          {!emptyText && (
            <span className="inner-simple-block__description">
              <RichTextRender content={step.description} parent={step}/>
            </span>
          )}

          {!!stepDescriptionInsideStepCount ? stepChildren?.map(stepChild => (
            stepChild?.placement?.position === EBlockPosition.insideStepDescription && (
              stepChild?.__component !== EComponent?.PARAGRAPH ? (
                <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>
              ) : (
                <span className="inner-simple-block__description">
                  <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>
                </span>
              )
            ))) : children}
        </div>
      )}
    </div>
  )
}

const StepTableHeader: FC<{step: IStepComponent, stepChildren: TComponent[], children?: ReactNode}> = (
  {step, stepChildren, children}) => {

  const {emptyText, stepDescriptionInsideStepCount, headerClasses} = useStepHeaderSettings({
    step, stepChildren, children,
    classes: {
      mainClass: 'inner-table__title',
      marginClass: 'mb-40 mb-sm-40',
      mobileSmallTitleClass: 'inner-table__title--small'
    }
  })

  return (
    <>
      {step?.title && (
        <h2 className={headerClasses?.join(' ')}>
          <Text text={step.title}/></h2>
      )}
      {!emptyText && (
        <div className={"inner-grid__content mb-65 mb-md-40 mb-sm-40"}>
          <RichTextRender content={step.description} parent={step}/>

          {(!!stepDescriptionInsideStepCount || children) && (
            <div>
              {!!stepDescriptionInsideStepCount ? stepChildren?.map(stepChild => (
                stepChild?.placement?.position === EBlockPosition.insideStepDescription && (
                  <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>)
              )) : children}
            </div>
          )}
        </div>
      )}
    </>
  )
}

const StepToolsHeader: FC<{step: IStepComponent, stepChildren: TComponent[], children?: ReactNode}> = (
  {step, stepChildren, children}) => {

  const {emptyText, stepDescriptionInsideStepCount, headerClasses} = useStepHeaderSettings({
    step, stepChildren, children,
    classes: {
      mainClass: 'inner-tools__title',
      mobileNormalTitleClass: 'inner-tools__title--large'
    }
  })

  return (
    <>
      {(step?.title || step?.description || stepChildren.length || children) && (
        <div className={`inner-tools__content`}>
          {step?.title && (
            <h2 className={headerClasses?.join(' ')}><Text text={step.title}/></h2>
          )}
          {!emptyText && (
            <div className="inner-tools__text">
              <RichTextRender content={step.description} parent={null}/>
            </div>
          )}
          {!!stepDescriptionInsideStepCount ? stepChildren?.map(stepChild => (
            stepChild?.placement?.position === EBlockPosition.insideStepDescription && (
              <StepChildren key={stepChild?.id} components={[stepChild]} parentStep={step}/>)
          )) : children}
        </div>
      )}
    </>
  )
}

export default memo(Step)
