import React, {FC, memo, useMemo} from 'react';
import {useSelectTag} from '@common/hooks/useSelectTag';
import {ICaseListItem} from '@common/types';
import RichTextRender from '@components/component/RichTextRender';
import {isNotEmptyRichContent} from '@common/functions';
import Text from '@components/Text';


const TimeLineItem: FC<{item: ICaseListItem, rowIndex?: number, isDate?: boolean}> = ({item, rowIndex, isDate= true}) => {
  const {setSelectedTagSlug} = useSelectTag('projects')

  const emptyText = useMemo(() => !isNotEmptyRichContent(item?.text), [item])

  return (
    <div className="project__text-item" key={rowIndex}>
      <div className="project__text-left">
        <h2 className="project__text-title">
          <Text text={item.title}/>
        </h2>
        <span className="project__text-note">
          <Text text={item.note}/></span>
        <span className="project__text-year project__text-year--desktop-none">
          {item.date}
        </span>
      </div>
      <div className={isDate ? "project__text-middle" : "project__text-full"}>
        {(!emptyText || item?.description) && (
          <div className="project__text-description">
            {emptyText ? (<p>{item?.description}</p>) : (<RichTextRender content={item?.text || []}/>)}
          </div>
        )}
        {!!item?.tags?.length && (
          <div className="project__text-tags">
            {item.tags?.map((t, index) => (typeof t !== 'string') ? (
              <a key={`project_${rowIndex}_${index}`} className={`project__text-tag`} href={`/projects#${t?.slug}`}
                 onClick={() => setSelectedTagSlug(typeof t !== 'string' ? t?.slug : null)}>
                {'name' in t ? t?.name : t}</a>
            ) : (
              <span  key={`table_${rowIndex}_${index}`} className={`project__text-tag`}>{t}</span>
            ))}
          </div>
        )}
      </div>
      {isDate && (
        <div className="project__text-right project__text-right--mobile-none">
          <span className="project__text-year">
            {item.date}
          </span>
        </div>
      )}
    </div>
  )
}

export default memo(TimeLineItem)
